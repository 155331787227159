/**
 * @todo refactoring
 * @todo integration
 */
import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'

import Style from '../styles/pages/sitemap.module.scss'

class Sitemap extends React.Component {

  constructor() {
    super();
    this.sitemap = {};
  }

  _t = (str) => {
    const _t = this.props.data.staticText.edges[0].node.data.texts;
    return _t[_t.findIndex(item => item.key_text === str)].text.text;
  };

  render() {
    const { 
      allSitePage,
      allPrismicStaticpagesseo,
      staticText
    } = this.props.data;
    
    const pages = allSitePage.edges
    .filter(i => (!i.node.context.parent && i.node.context.title))
    .map((page) => {
      return {
        title: page.node.context.title,
        path: page.node.path,
        uid: page.node.context.uid,
        pages: allSitePage.edges.filter(i => i.node.context.parent === page.node.context.uid).map((i) => {
          return {
            ...i.node
          }
        })
      }
    });

    return (
      <Layout 
        context={this.props.pageContext} 
        pageTitle={allPrismicStaticpagesseo.edges[0].node.data.sitemap_page_title.text}
        _t={staticText.edges[0].node.data.texts}
      >
        <SEO 
          pageTitle={allPrismicStaticpagesseo.edges[0].node.data.sitemap_page_title.text} 
          pageDescription={allPrismicStaticpagesseo.edges[0].node.data.sitemap_page_description.text} 
          context={this.props.pageContext}
        />
        <div className="page">
          <section className="regular">
            <div className={`page-head`}>
              <h2>{this._t('Plan du site')}</h2>
            </div>
            <div className={`${Style.container} container`}>
              <ul>
                {
                  pages.map((page) => {
                    return (
                      <li key={`${page.uid}`}>
                        <Link to={page.path}>{page.title}</Link>
                        {
                          page.pages.length > 0 && (
                            <ul>
                              {
                                page.pages.map((i) => {
                                  return (
                                    <li key={`${i.context.uid}`}>
                                      <Link to={i.path}>{i.context.title}</Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          )
                        }
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Sitemap;

export const query = graphql`
query SiteMap($locale: String!) {
  allSitePage(filter: {context: {
    sitemap: {eq: true}
    hidefromsitemap: {eq: false}
    canonical: {eq: true}
    locale: { eq: $locale}
  }}) {
    edges {
      node {
        context {
          parent
          uid
          title
        }
        path
      }
    }
  }
  allPrismicStaticpagesseo(filter: { lang: { eq: $locale} }) {
    edges {
      ...StaticPagesSeo
    }
  }
  staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
    edges { ...StaticTexts }
  }
}`